export const ALL_ROOM_REQUEST = "ALL_ROOM_REQUEST";
export const ALL_ROOM_SUCCESS = "ALL_ROOM_SUCCESS";
export const ALL_ROOM_FAIL = "ALL_ROOM_FAIL";

export const ADMIN_ROOM_REQUEST = "ADMIN_ROOM_REQUEST";
export const ADMIN_ROOM_SUCCESS = "ADMIN_ROOM_SUCCESS";
export const ADMIN_ROOM_FAIL = "ADMIN_ROOM_FAIL";

export const NEW_ROOM_REQUEST = "NEW_ROOM_REQUEST";
export const NEW_ROOM_SUCCESS = "NEW_ROOM_SUCCESS";
export const NEW_ROOM_RESET = "NEW_ROOM_RESET";
export const NEW_ROOM_FAIL = "NEW_ROOM_FAIL";

export const UPDATE_ROOM_REQUEST = "UPDATE_ROOM_REQUEST";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";
export const UPDATE_ROOM_RESET = "UPDATE_ROOM_RESET";
export const UPDATE_ROOM_FAIL = "UPDATE_ROOM_FAIL";

export const DELETE_ROOM_REQUEST = "DELETE_ROOM_REQUEST";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_RESET = "DELETE_ROOM_RESET";
export const DELETE_ROOM_FAIL = "DELETE_ROOM_FAIL";

export const ROOM_DETAILS_REQUEST = "ROOM_DETAILS_REQUEST";
export const ROOM_DETAILS_SUCCESS = "ROOM_DETAILS_SUCCESS";
export const ROOM_DETAILS_FAIL = "ROOM_DETAILS_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
